// googleTag.js
export const addGoogleTag = (nonce) => {
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-5HKVCPG30X';
  script1.setAttribute('nonce', nonce);
  document.head.appendChild(script1);

  script1.onload = () => {
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-5HKVCPG30X');
    `;
    script2.setAttribute('nonce', nonce);
    document.head.appendChild(script2);
  };
};